<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
          <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" v-model:endDefault="endDefault" :withToday="withToday"/>
        </div>
        <div class="searchbar-content">
          <div class="title"><span>{{ $t('common.sOptiont') }}</span></div>
          <select v-model="orderStr">
            <option value="partnerLevelOrd_1">파트너 등급별 오름차순</option>
            <option value="partnerLevelOrd_-1">파트너 등급별 내림차순</option>
            <option value="cashAmt_-1">{{ $t('common.olm') }}</option>
            <option value="cashAmt_1">{{ $t('common.osm') }}</option>
            <option value="winLose_-1">{{ $t('common.olwl') }}</option>
            <option value="winLose_1">{{ $t('common.oswl') }}</option>
            <option value="cashIn_-1">{{ $t('common.old') }}</option>
            <option value="cashIn_1">{{ $t('common.osd') }}</option>
          </select>
        </div>
      </div>
      <div class="searchBar">
         <div :class="'searchbar-content'">
            <div class="title"><span>{{ $t('table.head.category') }}</span></div>
            <div>
               <select class="mr-5">
                 <option value="memId" selected>{{ $t('searchArea.id') }}</option>
               </select>
               <input type="text" class="mr-5" v-model="reqData.memId"/>
               <button class="btn-search" type="button" @click="setTableData()">
                 <i class="fas fa-search"></i>
               </button>
            </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <i class="fas fa-search"></i>
            <span>{{ $t(`searchArea.schResult`) }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value" v-if="tableData.length !== 0">{{ tableData.length }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <!--table-head :headInfo="headInfo" /-->
          <thead>
             <tr>
               <th>{{ $t("table.head.index") }}</th>
               <th>{{ $t("table.head.memId") }}<br />{{ $t("table.head.nick") }}</th>
               <th>{{ $t("table.head.upper") }}</th>
               <th>{{ $t("table.head.cashAmt") }}</th>
               <th>{{ $t("table.head.pointAmt") }}</th>
               <th>{{ $t("table.head.connectTime") }}<br />{{ $t("table.head.connectIp") }}</th>
               <th>{{ $t("table.head.allInOut") }}</th>
               <th>{{ $t("header.inoutAmt") }}</th>
               <th>{{ $t("table.body.betAmts") }}<br />{{ $t("table.body.winAmts") }}</th>
               <th>{{ $t("table.head.betwlt") }}</th>
               <th>{{ $t("table.head.recentgame") }}<br />{{ $t("table.head.gameName") }}</th>
               <th>{{ $t("user.isLogin") }}</th>
               <th>{{ $t("user.isBetting2") }}</th>
               <th>{{ $t("table.body.move") }}</th>
               <th>{{ $t("table.head.forceout") }}</th>
             </tr>
          </thead>
          <tbody>
          <template v-if="tableData && tableData.length > 0">
            <tr v-for=" (item, idx) in tableData" :key="item.memId">
              <td class="roboto">{{idx+1}}</td>
              <td>
                 <div class="fx fx-col gap-5">
                   <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
                   <button type="button" class="fc-nick btn-link" :class="item.partnerLevel" @click="detailOpen('user', item.memId)"><span class="box">{{ item.memNick }}</span></button>
                 </div>
              </td>
              <!-- <td>
                <div class="topwrap" v-if="item.isTopUserList">
                  <select @change="onDetailOpen" v-if="item.isTopUserList" :class="item.partnerLevel">
                    <template v-if="item.isTopUserList">
                      <template v-for="(user, index) in item.topUserListCalculate" :key="user.recommenderId">
                        <option :value="user.recommenderId" class="topuser" :class="user.partnerLevel">
                          <span class="topbox">[ {{computedPartnerLevel(user)}} ] </span>
                          <span :class="user.partnerLevel">{{user.recommenderId}}</span>
                        </option>
                      </template>
                    </template>
                  </select>
                </div>
              </td> -->
              <td>
                <div class="topwrap" v-if="item.topUserList && item.topUserList.length > 0 && item.topUserList !== '0'">
                  <select class="select">
                    <option class="option" v-for="pt in item.topUserList" :key="pt.recommenderId">
                      <span class="topbox">[{{computedPartnerLevel(pt)}}] </span>
                      <span>{{pt.recommenderId}}</span>
                    </option>
                  </select>
                  <i class="icon">+</i>
                </div>
                <div v-else>
                  -
                </div>
              </td>
              <td>
                 <div class="flexSBandC">
                   <span class="roboto">{{numberWithCommas(item.cashAmt)}}</span>
                   <!--span class="re" @click="getUserCashAmt(item)"><img src="@/assets/img/icon_f5.svg"></span-->
                 </div>
              </td>
              <!--<td width="5%">
                <ui-toggle v-if="item.partnerLevel !== 'NORMAL'" :textType="'default'" :checked="item.recommendYn" @setYn="setYn('recommendYn',item)" />
                <template v-else>

                </template>
              </td>
              <td>{{item.memStatus}}</td-->
              <td class="roboto">{{numberWithCommas(item.pointAmt)}}</td>
              <td>
                 <div class="ipwrap roboto">
                    {{item.loginDt}} <!--{{item.sessionSecond}} ({{item.totalLoginCount}})-->
                    <span class="ip">{{item.loginIP}}</span>
                 </div>
              </td>
              <td>
                 <ul class="money">
                   <li><em>{{ $t('table.body.totalCashInAmt') }}:</em><span>{{ numberWithCommas(item.cashIn) }}</span></li>
                   <li><em>{{ $t('table.body.totalCashOutAmt') }}:</em><span>{{ numberWithCommas(item.cashOut) }}</span></li>
                 </ul>
              </td>
              <td :class="Number(item.cashIn) - Number(item.cashOut) < 0 ? 'fc-blue' : 'fc-red'" class="roboto">{{ numberWithCommas(Number(item.cashIn) - Number(item.cashOut)) }}</td>
              <td>
                 <ul class="money">
                   <li><em>{{ $t('table.body.betAmts') }} :</em><span>{{ numberWithCommas(item.betAmt) }}</span></li>
                   <li><em>{{ $t('table.body.winAmts') }} :</em><span>{{ numberWithCommas(item.winAmt) }}</span></li>
                 </ul>
              </td>
              <td :class="Number(item.betAmt) - Number(item.winAmt) < 0 ? 'fc-blue' : 'fc-red'" class="roboto">{{ numberWithCommas(Number(item.betAmt) - Number(item.winAmt)) }}</td>
              <td>
                 <div class="fx fx-col gap-5" v-html="formatData(item.betData)"></div>
              </td>
              <td><ui-toggle :textType="'default'" :checked="item.loginYn" @setYn="setYn('loginYn',item)" /></td>
              <td><ui-toggle :textType="'default'" :checked="item.betYn" v-if="item.partnerLevel == 'NORMAL'" @setYn="setYn('betYn',item)" /></td>
              <td>
                <div class="status-change-btn-wrap">
                  <a class="btn-inout" @click="parentLinkMove('/bet/list/all', item.memId)">{{ $t('header.betList') }}</a>
                </div>
              </td>
              <td>
                <div class="status-change-btn-wrap">
                  <a class="btn-inout bg-rd" @click="memberLogout(item.memId)">{{ $t('button.logout') }}</a>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="15">{{ $t('txt.noData') }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
import DateSelector from '@/components/common/DateSelector'
import uiToggle from '@/components/ui/UiToggle'
import { getUserCashAmtAPI, loginList, memberModify, memLogout } from '@/api/member.js'
import { getDateStr, numberWithCommas, replaceDateT, timeConverter_seconds } from '@/libs/utils.js'
import { addDays } from 'date-fns'
import Memo from '@/components/common/memo'
import { PARTNER_LEVEL } from '@/libs/constants'

export default {
  name: 'loginList',
  components: {
    uiToggle,
    DateSelector,
    Memo
  },
  data: function () {
    return {
      orderStr: 'partnerLevelOrd_1',
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'memId', 'cashAmt', 'loginYn', 'betYn', 'loginDt', 'lastLoginIp', 'deWith', 'betWin', 'recentgame', 'listck', 'forceout']
      },
      reqData: {
        startDate: '',
        endDate: ''
      },
      tableData: []
    }
  },
  watch: {
    orderStr () {
      this.getOrderData()
    }
  },
  methods: {
    getOrderData () {
      const temp = this.tableData
      if (temp && temp.length > 0) {
        const sortField = this.orderStr.split('_')[0]
        const sort = this.orderStr.split('_')[1]
        temp.sort((a, b) => {
          let aTargetField = a[sortField]
          let bTargetField = b[sortField]
          if (sortField !== 'partnerLevelOrder') {
            aTargetField = Number(aTargetField)
            bTargetField = Number(bTargetField)
          }

          if (aTargetField !== bTargetField) {
            if (sort === '-1') {
              return aTargetField > bTargetField ? -1 : 1
            } else {
              return aTargetField > bTargetField ? 1 : -1
            }
          } else {
            return 0
          }
        })

        this.tableData = temp
      }
    },
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    block () {
      if (!confirm('해당 IP를 차단시겠습니까?')) {
        alert('취소를 누르셨습니다.')
      } else {
        alert('차단 완료')
      }
    },
    onDetailOpen (event) {
      const selectedRecommenderId = event.target.value
      this.detailOpen('partner', selectedRecommenderId)
    },
    formatData (betData) {
      if (betData) {
        const idx = betData.indexOf('[')
        if (idx >= 0) {
          const vendorNm = betData.substring(0, idx - 1)
          const gameNm = betData.substring(idx, betData.length)
          return vendorNm + '<span>' + gameNm + '</span>'
        } else {
          return betData
        }
      } else {
        return ''
      }
    },
    parentLinkMove (linkTarget, memId) {
      window.open(`${linkTarget}?id=${memId}`, '_blank')
    },
    async getUserCashAmt (item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: item.memId
      }
      await getUserCashAmtAPI(params).then(result => {
        this.emitter.emit('Loading', false)
        if (result.resultCode === '0') {
          const data = result.data
          const userCashAmt = data.userCashAmt.cashAmt
          item.cashAmt = userCashAmt
        }
      }).catch(e => {
        this.emitter.emit('Loading', false)
      })
    },
    async setYn (key, item) {
      item[key] = !item[key]
      console.log(item)
      const req = {
        memId: item.memId
      }
      if (key === 'loginYn' && !item.loginYn) {
        await this.memberLogout(item.memId)
      }

      req[key] = item[key]
      if (req[key]) {
        req[key] = 'Y'
      } else {
        req[key] = 'N'
      }

      const res = await memberModify(req)
      if (res.resultCode === '0') {
        alert('변경 완료')
        await this.setTableData()
      } else {
        alert('변경 실패, 다시 시도해주세요.')
      }
    },
    async memberLogout (memId) {
      const req = {
        memId
      }
      const res = await memLogout(req)
      if (res.resultCode === '0') {
        if (!confirm('해당 회원' + memId + '을 강제 로그아웃 처리하시겠습니까?')) {
          alert('취소를 누르셨습니다.')
        } else {
          alert('로그아웃 완료')
          await this.setTableData()
        }
      } else {
        alert('로그아웃 실패, 다시 시도해주세요.')
      }
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = addDays(new Date(), 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      console.log('setEndDate : ', date)
      let _date = ''
      if (!date) {
        _date = addDays(new Date(), 0)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    async setTableData () {
      this.emitter.emit('Loading', true)

      console.log('[req] login : ', this.reqData)
      const req = { ...this.reqData }
      const res = await loginList(req)
      console.log('[res] login : ', res)

      const temp = res.data.list
      const sortField = this.orderStr.split('_')[0]
      const sort = this.orderStr.split('_')[1]
      temp.sort((a, b) => {
        if (sort === '-1') {
          return a[sortField] > b[sortField] ? 1 : -1
        } else {
          return a[sortField] > b[sortField] ? -1 : 1
        }
      })
      for (let item of temp) {
        item = this.dataConverter(item)
      }
      this.tableData = temp
      this.getOrderData()

      this.emitter.emit('Loading', false)
    },
    dataConverter (data) {
      switch (data.memStatus) {
        case '1':{
          data.memStatus = '정상'
          break
        }
        case '0':{
          data.memStatus = '신청'
          break
        }
        case '2':{
          data.memStatus = '대기'
          break
        }
        case '-1':{
          data.memStatus = '거절'
          break
        }
      }
      data.loginDt = replaceDateT(data.loginDt)
      data.totalLoginCount = numberWithCommas(data.totalLoginCount)
      data.sessionSecond = timeConverter_seconds(data.sessionSecond)
      for (const key in data) {
        if (key.indexOf('Yn') > -1) {
          if (data[key] === 'Y') {
            data[key] = true
          } else {
            data[key] = false
          }
        }
      }
      return data
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    await this.setStartDate()
    await this.setEndDate()
    await this.setTableData()
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
td .box {max-width: max-content;}
.fx-ai-end {align-items: flex-end;}
.work {color: red;font-size: 12px;margin-left: 2px;}
.flexSBandC {display: flex; justify-content: center; align-items: center;gap: 10px;}
.flexSBandC span.re img {cursor: pointer;}
.ipbtn {width: 200px;display: inline-table;cursor: pointer;color: #bf0000;white-space: pre-wrap;word-break: break-word;}
.ipbtn:hover {text-decoration: underline;}
.rdc {color: #750404;}
.rdc2 {color: #9b0966;}
.topwrap{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.topwrap button{
  width: 70px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.money li:first-child {margin-top: 0;}
.money li {display: flex;justify-content: space-between;align-items: center;margin-top: 7px;}
.money em {width: 60px;text-align: left;padding-left: 15px;box-sizing: border-box;}
.money span {width: 102px;text-align: right;display: inline-block;font-family: 'Roboto';font-size: 14px;padding-right: 15px;box-sizing: border-box;}
</style>
